import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../font/style.css'
import './index.css'

class IndexPage extends React.Component {
  state = {
    iconData: null,
    term: "apple"
  }

  componentDidMount(){
    this.fetchByTerm("apple");
  }

  fetchByTerm(term){
    if(this.timeout){
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      fetch(`https://api.svgapi.com/v1/Ty5WcDa63E/list/?search=${term}&limit=20`).then(res => res.json()).then(resJson => {
        this.setState({iconData: resJson});
      });
    }, 200);
  }

  renderIcons(){
    if(this.state.iconData){
      return this.state.iconData.icons.map((icon, i) => {
        return (<img src={icon.url} key={icon.id}/>);
      })
    }
  }

  render(){
    return (
      <Layout>
        <SEO title="svgapi - Contact Us" description="You can find contact information about us and our company."/>
        <div className="register">
          <div className={this.state.registered ? "side registered" : "side"}>
            <div className="sideInner">
              <div className="header">
                <a href="/"><img src={require("../images/logo_white.svg")} className="logo"/></a>
              </div>
              <div className="sideInfo">
                <h1>Contact Us</h1>
                <p>We are here to answer your questions and solve your problems every weekday.</p>
                <p>Expect an answer within 1-2 business days.</p>
              </div>
              <div className="sideLinks">
                <a href="https://panel.svgapi.com">Admin</a>
                <Link to="/pricing">Pricing</Link>
                <Link to="/documentation">Documentation</Link>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>

          </div>


          <div className="sideHolder"></div>
          <div className="form">
            <div className="formInner">

              <h2>Getting Touch with Us</h2>
              <p>We are ready to help you out as much as we can on any problems you face during your use of our API or questions you might have while considering using it.</p>

              <p>Email: <a href="mailto:info@svgapi.com">info@svgapi.com</a></p>

              <p>Or send me a direct message on twitter, for a one liner question on <a href="https://twitter.com/BurakTokak">@BurakTokak</a></p>

              <h2>Company Information</h2>

              <p>Please get in touch with us for legal inquiries via our <a href="https://dreamoriented.org" target="_blank">website</a> or email address (<a href="mailto:info@dreamoriented.org">info@dreamoriented.org</a>).</p>
              <p>Dream Oriented Ltd.<br/>
                 Turkey, TIN: 3131169763<br/>
                 <a href="https://dreamoriented.org">dreamoriented.org</a>
              </p>

              <p>Please get in touch with legal inquiries regarding content, unauthorized distribution miss licensing or takedown notices; <a href="mailto:legal@svgapi.com">legal@svgapi.com</a></p>

              <footer style={{fontSize: "0.9em", paddingTop: 20, paddingBottom: 30}}>
                Copyright © {new Date().getFullYear()}, {` `} Dream Oriented Ltd.<br/>
                <small>We collect payments using Paddle.com's services with the company name Dream Oriented Ltd.</small>
                <br/>
                <small><a href="https://paddle.com/legal-buyers/" target="_blank">Paddle Buyer Terms and Conditions</a></small>
              </footer>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
